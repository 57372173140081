<div class="modal-header">
  <h4 class="modal-title" data-cy="verifyAccountTitle">Please verify your account information</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body m-1">
  @if (errorMessage()) {
    <div class="alert alert-danger" role="alert">
      <div class="alert-message"><strong>There was an issue: </strong> {{ errorMessage() }}</div>
    </div>
  }
  <form [formGroup]="form()">
    <formly-form [form]="form()" [fields]="fields()" [model]="currentUser()" data-cy="modalForm" />
  </form>
</div>
<div class="modal-footer">
  <chitin-button label="Cancel" (clicked)="activeModal.dismiss()" [outline]="true" />
  <chitin-button label="Save changes" (clicked)="confirm()" [disabled]="!form().valid" [isLoading]="isLoading()" data-cy="submitBtn" />
</div>
