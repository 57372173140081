import { Component, EventEmitter, Output, computed, inject, signal } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { AuthService } from '@services/auth.service';
import { UserService } from '@services/user.service';
import { createFormGroup } from '@shared/components/forms/form';
import { AccountInfoForm } from '@shared/components/verify-account-info/verify-account-info-form';
import { IUser } from '@shared/models/user.model';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

@Component({
  selector: 'app-verify-account-info',
  templateUrl: './verify-account-info.component.html',
  styleUrls: ['./verify-account-info.component.scss'],
})
export class VerifyAccountInfoComponent {
  @Output() verify = new EventEmitter<IUser>();

  protected activeModal = inject(NgbActiveModal);
  private userService = inject(UserService);
  private authService = inject(AuthService);

  currentUser = signal<IUser | undefined>(this.authService.getCurrentUser());

  form = signal(createFormGroup<IUser>());
  fields = computed<FormlyFieldConfig[]>(() => AccountInfoForm.create(cloneDeep(this.currentUser()), this.userService));

  isLoading = signal(false);
  errorMessage = signal('');

  confirm(): void {
    this.errorMessage.set('');
    this.updateUsersDateVerified();
    this.saveUser();
  }

  private updateUsersDateVerified(): void {
    const currentUser = this.currentUser();
    if (currentUser) {
      currentUser.date_verified = moment(new Date()).toISOString();
    }
  }

  private saveUser(): void {
    this.isLoading.set(true);
    const currentUser = this.currentUser();
    if (!currentUser) {
      return;
    }
    this.userService
      .updateCurrentUser(currentUser)
      .pipe(
        finalize(() => this.isLoading.set(false)),
        catchError(error => {
          this.errorMessage.set('Unable to save user');
          return of(error);
        }),
      )
      .subscribe(result => {
        if (result && !result.error) {
          this.authService.saveUser(currentUser);
          this.verify.emit(currentUser);
          this.activeModal.dismiss();
        }
      });
  }
}
