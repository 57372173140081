import { AbstractControl, ValidationErrors } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { UserService } from '@services/user.service';
import { IUser } from '@shared/models/user.model';
import { lastValueFrom, of } from 'rxjs';

export class AccountInfoForm {
  static create(currentUser: IUser | undefined, userService: UserService): FormlyFieldConfig[] {
    return [
      {
        fieldGroupClassName: 'row mb-2',
        fieldGroup: [
          {
            className: 'col-6',
            key: 'first_name',
            type: 'input',
            props: { label: 'First Name', required: true, cols: 6 },
          },
          {
            className: 'col-6',
            key: 'last_name',
            type: 'input',
            props: { label: 'Last Name', required: true, cols: 6 },
          },
        ],
      },
      {
        className: 'row mb-2',
        key: 'email',
        type: 'input',
        props: { label: 'Email', type: 'email', required: true },
        modelOptions: {
          updateOn: 'blur',
        },
        asyncValidators: {
          validation: [this.getEmailValidator(currentUser, userService)],
        },
      },
    ];
  }

  static getEmailValidator(currentUser: IUser | undefined, userService: UserService) {
    return async (control: AbstractControl): Promise<ValidationErrors | null> => {
      if (control.value) {
        const isUnique = await lastValueFrom(userService.isEmailUnique(control.value));
        if (!isUnique && (!currentUser || currentUser.email !== control.value)) return { alreadyUsed: true };
      }
      if (control.value.split('@').length <= 1) return lastValueFrom(of({ email: true }));
      return lastValueFrom(of(null));
    };
  }
}
